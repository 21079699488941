import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/prof/prof/deps/docs/src/components/AutoDocLayout.jsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Name`}</h2>
    <p><strong parentName="p">{`step certificate inspect`}</strong>{` -- print certificate or CSR details in human readable format`}</p>
    <h2>{`Usage`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-raw"
      }}>{`step certificate inspect <crt-file>
[--bundle] [--short] [--format=<format>] [--roots=<root-bundle>]
[--servername=<servername>]
`}</code></pre>
    <h2>{`Description`}</h2>
    <p><strong parentName="p">{`step certificate inspect`}</strong>{` prints the details of a certificate
or CSR in a human readable format. Output from the inspect command is printed to
STDERR instead of STDOUT. This is an intentional barrier to accidental
misuse: scripts should never rely on the contents of an unvalidated certificate.
For scripting purposes, use `}<strong parentName="p">{`step certificate verify`}</strong>{`.`}</p>
    <p>{`If crt-file contains multiple certificates (i.e., it is a certificate "bundle")
the first certificate in the bundle will be output. Pass the --bundle option to
print all certificates in the order in which they appear in the bundle.`}</p>
    <h2>{`Positional arguments`}</h2>
    <p><inlineCode parentName="p">{`crt-file`}</inlineCode>{`
Path to a certificate or certificate signing request (CSR) to inspect. A hyphen ("-") indicates STDIN as `}<inlineCode parentName="p">{`crt-file`}</inlineCode>{`.`}</p>
    <h2>{`Options`}</h2>
    <p><strong parentName="p">{`--format`}</strong>{`=`}<inlineCode parentName="p">{`format`}</inlineCode>{`
The output format for printing the introspection details.`}</p>
    <p><inlineCode parentName="p">{`format`}</inlineCode>{` is a string and must be one of:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`text`}</strong>{`: Print output in unstructured text suitable for a human to read.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`json`}</strong>{`: Print output in JSON format.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`pem`}</strong>{`: Print output in PEM format.`}</p>
      </li>
    </ul>
    <p><strong parentName="p">{`--roots`}</strong>{`=`}<inlineCode parentName="p">{`roots`}</inlineCode>{`
Root certificate(s) that will be used to verify the
authenticity of the remote server.`}</p>
    <p><inlineCode parentName="p">{`roots`}</inlineCode>{` is a case-sensitive string and may be one of:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`file`}</strong>{`: Relative or full path to a file. All certificates in the file will be used for path validation.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`list of files`}</strong>{`: Comma-separated list of relative or full file paths. Every PEM encoded certificate from each file will be used for path validation.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`directory`}</strong>{`: Relative or full path to a directory. Every PEM encoded certificate from each file in the directory will be used for path validation.`}</p>
      </li>
    </ul>
    <p><strong parentName="p">{`--servername`}</strong>{`=`}<inlineCode parentName="p">{`value`}</inlineCode>{`
TLS Server Name Indication that should be sent to request a specific certificate from the server.`}</p>
    <p><strong parentName="p">{`--bundle`}</strong>{`
Print all certificates in the order in which they appear in the bundle.
If the output format is 'json' then output a list of certificates, even if
the bundle only contains one certificate. This flag will result in an error
if the input bundle includes any PEM that does not have type CERTIFICATE.`}</p>
    <p><strong parentName="p">{`--short`}</strong>{`
Print the certificate or CSR details in shorter and more friendly format.`}</p>
    <p><strong parentName="p">{`--insecure`}</strong>{`
Use an insecure client to retrieve a remote peer certificate. Useful for
debugging invalid certificates remotely.`}</p>
    <h2>{`Exit codes`}</h2>
    <p>{`This command returns 0 on success and >0 if any error occurs.`}</p>
    <h2>{`Examples`}</h2>
    <p>{`Inspect a local certificate (default to text format):`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step certificate inspect ./certificate.crt
`}</code></pre>
    <p>{`Inspect a local certificate bundle (default to text format):`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step certificate inspect ./certificate-bundle.crt --bundle
`}</code></pre>
    <p>{`Inspect a local certificate in json format:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step certificate inspect ./certificate.crt --format json
`}</code></pre>
    <p>{`Inspect a local certificate bundle in json format:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step certificate inspect ./certificate.crt --format json --bundle
`}</code></pre>
    <p>{`Inspect a remote certificate (using the default root certificate bundle to verify the server):`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step certificate inspect https://smallstep.com
`}</code></pre>
    <p>{`Inspect a remote certificate (using the standard port derived from the URL prefix):`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step certificate inspect smtps://smtp.gmail.com
`}</code></pre>
    <p>{`Inspect an invalid remote certificate:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step certificate inspect --insecure https://expired.badssl.com
`}</code></pre>
    <p>{`Inspect a remote certificate chain (using the default root certificate bundle to verify the server):`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step certificate inspect https://google.com --bundle
`}</code></pre>
    <p>{`Inspect a remote certificate using a custom root certificate to verify the server:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step certificate inspect https://smallstep.com --roots ./root-ca.crt
`}</code></pre>
    <p>{`Inspect a remote certificate using a custom list of root certificates to verify the server:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step certificate inspect https://smallstep.com \\
--roots "./root-ca.crt,./root-ca2.crt,/root-ca3.crt"
`}</code></pre>
    <p>{`Inspect a remote certificate using a custom directory of root certificates to verify the server:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step certificate inspect https://smallstep.com \\
--roots "./path/to/root/certificates/"
`}</code></pre>
    <p>{`Inspect a remote certificate chain in json format using a custom directory of
root certificates to verify the server:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step certificate inspect https://google.com --format json \\
--roots "./path/to/root/certificates/" --bundle
`}</code></pre>
    <p>{`Inspect a remote certificate chain in PEM format:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step certificate inspect https://smallstep.com --format pem --bundle
`}</code></pre>
    <p>{`Inspect a local CSR in text format (default):`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step certificate inspect foo.csr
`}</code></pre>
    <p>{`Inspect a local CSR in json:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step certificate inspect foo.csr --format json
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      